const zh = {
    logo: '../../assets/images/logo1-02.svg',
    classify:{ 
    	title: [{title: '主页',href:'/index',child: []},
                {title: '关于',href:'/about',child: [{title: '历届回顾',href:'/aboutHis/9188'},{title: '最新动态',href:'/aboutHisNews'}]} ,
                {title: '组委会',href:'/committee',child: []},
                {title: '会议征稿',href:'/call',child: [{title: '论文提交',href:''}]} ,
                {title: '注册参会',href:'/metting',child: []} ,
                {title: '演讲嘉宾',href:'/speaker',child: []} ,
                {title: '联系我们',href:'/contentus',child: []} 
                
        ]     

    }
}
export default zh;