<template>
	<div id="top">
	  <!-- <banner :bannerUrl="bannerUrl"></banner> -->
	  <section class="hero-area">
  <div class="hero-slider">
	  callforpaperBg.jpg
	  <div class="breadcrumbs overlay" :style="{backgroundImage: 'url(' + require('../assets/images/banner01.jpg') + ')' }">
  <div class="container">
  <div class="row align-items-center">
  <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
  <div class="breadcrumbs-content">
  <h1 class="page-title">关于我们</h1>
  <p>我们荣幸地宣布，2024年第二届储能与智慧电站关键技术论坛即将盛大召开！邀您共探储能发展新篇章！</p>
  </div>
  <ul class="breadcrumb-nav">
  <li><a style="cursor:pointer" @click="goToIndex()">首页</a></li>
  <li>关于我们</li>
  </ul>
  </div>
  </div>
  </div>
  </div>
  </div>  
  </section>
  
  <section class="section blog-single">
  <div class="container">
  <div class="row">
  <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
  <div class="single-inner">
  
  <div class="post-details">
  <div class="detail-inner">
  
  <ul class="custom-flex post-meta">
  <li>
  <!-- <a href="javascript:void(0)">
  <i class="lni lni-calendar"></i>
  2024年07月24日
  </a> -->
  </li>
  </ul>
  <div >
	<p style="text-indent:2em;line-height: 24px;">
		<span style="font-size:14px;display: unset;">
			2024第二届储能与智慧电站关键技术论坛以“智慧赋能 驱动未来”为主题，将于2024年10月18日至10月20日在中国天津举行。
			2024第二届储能与智慧电站关键技术论坛旨在为我国储能技术、智能电网、智慧电站等领域的专家、学者和产业界人士搭建一个专业、创新的学术交流平台。
			会议将邀请中国工程院院士天津大学王成山教授团队、清华大学、北京航空航天大学、中国大唐集团、中国长江三峡集团上海勘测设计研究院、
			长江电力股份有限公司等知名学者和业内一线资深专家围绕“储能装备、水电装备、智慧电站、数字孪生、知识工程、智能设计、智能运维、增效扩容”等关键技术共同探讨，
			充分展示和分享创新理念和研究成果。
		</span>
	</p>
	<p style="font-size:14px;text-align: center;line-height: 24px;">
		<span>
			主办单位：<br>
			天津大学国家储能平台<br>
			天津仁爱学院<br>
			承办单位：<br>
			天津天发总厂机电设备有限公司<br>
			天津仁爱学院<br>
			协办单位：<br>
			中国水力发电工程学会继电保护与励磁专业委员会<br>
			天津机械工程学会<br>
			天津市电力工程协会<br>
			河北省水力发电工程学会<br>
			天津仁爱学院绿色水电研究院<br>
			支持媒体：<br>
			《机械设计》杂志社<br>
		</span>
	</p>
	<br>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>主办方</strong>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>天津大学国家储能技术产教融合创新平台</strong>
	</p>
	<p style="text-indent:2em;">
		储能是新兴产业、国家战略。天津大学作为首批获批国家储能技术产教融合创新平台的三所高校之一，贯彻教育部、国家发改委指导方针，
		聚焦国家能源战略布局，瞄准国际先进水平，依托新工科建设优势，整合高校和产业优质资源，推动储能技术领域人才培养以及科技创新，努力建设集
		人才培养、学科建设、科技攻关三位一体的国家储能技术产教融合创新平台（以下简称“国家储能平台”），
		为国家培养未来能够引领储能技术进步与产业发展的卓越工程师和科学家。<br>
		<span style="display: inline-block;text-indent: 2em;">天津大学国家储能平台2022年6月正式成立，项目投资总额为41655万元，集成电化学、
			新材料、氢能、智能电网和储能经济与政策领域的优势力量，首批建设设计
			电化学储能、燃料储能与应用、储能装备与系统、储能安全与运维和储能经济与政策
			五个研究中心，以天津大学北洋园校区第56教学楼为核心功能区，结合化工学院、材料学院、机械学院、自动化学院等国家和省部级重点实验室改造建设。
		</span>
		<span style="display: inline-block;text-indent: 2em;">天津大学国家储能平台2021年获批
			储能科学与工程本科储能科学与工程本科专业并已招收第一届本科生，2022年获批储能科学与工程交叉学科硕士学位授权点，2023年获批培育博士学位授权点，
			并依托未来技术学院开展储能人才培养，已经形成了有效的管理机制和完备的课程体系，有力支撑高层次人才培养。
			2022年9月，天津大学获批实施储能技术国家急需高层次人才培养专项
			建设高校，全国仅10所；“储能技术”也获批天津大学首批建设的6个校级学科交叉分中心
			之一。平台突破现有专业、学院、行业的限制，现已形成以金东寒院士、王成山院士、赵天寿院士3名院士为带头人、
			20余名国家级领军人才、40余名国家级青年人才领衔的跨学科、复合型、高层次的师资队伍，覆盖了储能的全环节和全链条。
		</span>
		<span style="display: inline-block;text-indent: 2em;">国家储能平台聚焦“两性一度”，坚持
			选拔、培养、评价、保障全方位谋划，专业、课程、教材、教法
			全要素改革，构建具有天大特色的储能系统人才培养体系，加快培养储能领域“高精尖缺”人才。作为校内交叉创新的“策源高地”，平台实施产教融合协同育人计划，
			以储能学科专业为主体（1），校内各优势学科为支撑、
			（N），联合储能行业龙头企业（X），将产教融合、科教融合、协同育人理念贯穿人才培养全过程，构建
			
				“1+N+X”产学研合作新模式，
			增加学生早实践、多实践、高水平实践机会，探索更加符合卓越工程师成长规律的教育教学模式，建设具有天津大学特色的储能领域高端人才培养体系，
			联合企业共同培养能够解决储能产业“卡脖子”技术和企业急需关键技术的优秀人才。平台已与国
			家能源集团、中国能建、中石化、南方电网、比亚迪、东方电气、上海捷氢、玉柴芯蓝、天合储能
			和金风科技
			等企业签署人才联合培养协议，支持专项博士生参与企业的研发项目，并鼓励企业优秀青年技术骨干申请攻读定向博士生。
		</span>
		<span style="display: inline-block;text-indent: 2em;">国家储能平台聚焦国家能源战略布局，坚持“发挥特色优势、服务区域发展，产学研教协同、实施开放共享”原则，
			发挥地方区域性特色优势共享产业和教育资源结合，加强对储能领域卓越工程人才的培养，提升储能领域
			电化学储能、燃料储能、储能装备与系统重点前沿方向的科研能力、科技攻关能力和水平，解决储能领域“卡脖子”问题和企业技术难题，
			打通储能领域人才链、创新链、产业链、供应链，建设集学科建设、科技攻关、人才培养三位一体
			的国家储能技术产教融合创新平台。
		</span>
		<span style="display: inline-block;text-indent: 2em;">2023年，是我们党第二个百年奋斗目标的起步之年，是天津大学深入落实“十四五”规划承上启下、
			加快高质量发展的关键时期，也是储能平台谋篇布局的关键一年。国家储能平台将以习近平新时代中国特色社会主义思想和党的二十大精神为指引，
			深入贯彻落实教育部、国家发改委要求，聚焦人才培养、学科建设、科技攻关等平台重点工作，努力建设
			国际一流的国家储能技术产教融合创新平台，为我国储能产业发展和国际竞争提供强有力的人才和技术支撑。
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>天津仁爱学院</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">天津仁爱学院前身天津大学仁爱学院，是2006年经教育部批准，
			天津大学和天津市仁爱集团合作举办的本科层次全日制普通高等学校。2021 年经教育部批准依法转设为天津仁爱学院。
		</span>
		<span style="display: inline-block;text-indent: 2em;">近年来，学校在天津市教委的年检中排名第一，
			连续三年在天津市高校就业质量考核中被评为“优秀”。连续两年在中国民办高校校友会排名第五。
		</span>
		<span style="display: inline-block;text-indent: 2em;">学校占地面积 1000 余亩，建筑面积约38万平方米。学校在校生近17000 人，
			教职工千余人。学校以工科为主，目前拥有36个招生的本科专业。学校以培养应用型人才为目标，高度重视校企合作、
			产学研基地的建设，与企业在产教融合、科教融通方面做出诸多成绩。学校与科大讯飞股份有限公司、中软国际教育科技集团等企业创办现代产业学院；
			与天地伟业等170余家单位共建产学研基地及校外实践基地，为企业持续输送人才。学校连续三年突破年科研经费一亿元，拥有一大批业务精干的企业特派员，
			为企业解决“卡脖子”问题。学校机械学科带头人张俊红教授团队荣获机械工业科学技术奖一等奖，是机械工业科技工作者的最高级别奖项。
			学校作为理事长单位发起成立天津市技术经理人发展促进会，实现科研成果重大突破。
		</span>
		<span style="display: inline-block;text-indent: 2em;">转设后学校、仁爱集团与天津大学签订全面人才培养协议，仁爱集团每年向天津大学支出3000万资源使用费，
			用于天津大学支持仁爱学院全面发展建设。近年来学校已选派多名优秀青年教师到天津大学攻读博士研究生，已选派200名优秀本科生到天津大学相关专业访学一年。
			这也是仁爱集团与天津大学为学校搭建的人才成长“新赛道”。
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>承办方</strong>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>天津天发总厂机电设备有限公司</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">天津发电设备总厂始建于1938年，于1958年扩建，国家定点生产成套水力发电设备、
			大型泵组的大型骨干企业。荣获国家、部委及天津市科技进步奖和优秀新产品奖。为我国水利、电力实业的发展做出了突出贡献。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			天津发电设备总厂发展并衍生出天津天发总厂机电设备有限公司、天发重型水电、天津阿尔斯通等几家水电设备专业制造公司及其他水利水电相关的制造商。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			六十余年来，天发系水电设备制造企业设计生产了混流式、贯流式、轴流式、抽水蓄能式成套水轮机组1500余套，其中贯流式机组800多台套，
			是世界上生产大中型贯流机组最多的厂家，成为全球化的水电设备服务商。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			天津天发总厂机电设备有限公司传承天发系60多年的水电历史并将天发技术予以延续及提升，打造“4S店服务，10价值”全生命周期养护理念，调整产业和项目执行模式，
			实行24小时快速响应机制，以满足市场和用户的需求。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			天津天发总厂始终坚持技术创新，现已是国家高新技术企业、国家科技型中小企业、天津市雏鹰企业，拥有多项专利和自有技术。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			天津天发总厂现已成为国电、华电、大唐、葛洲坝、华能、北方国际、中电投等央企合格供应商。截止到2020年底为300多个水电站提供了大修、改造、增效扩容、备品备件、
			技术支持和服务、整机、整体方案优化等水电站4S店服务。
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>协办方</strong>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>中国水力发电工程学会继电保护与励磁专业委员会简介</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">中国水力发电工程学会继电保护与励磁专业委员会（以下简称专委会）成立于2005年5月18日，
			创办伊始甫立宗旨——以更多更好的行业优秀工作者为依托，协助各水电行业的组织及企业开展好工作，为水电企业、社团和技术工作者，提供信息与合作交流的平台。
			促进同行之间及时了解国内外最新技术前沿，共同探讨学科发展新成果和新进展，推动我国电厂继电保护及安全自动装置、励磁和直流系统等专业向更安全、更智能、
			更高效方向发展。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			目前专委会直接联系和服务会员数量高达2万余人，广泛覆盖水电行业主要产学研相关机构，业已成为水电二次专业初具影响力的社会团体。经十余年的发展，
			专委会影响力与日俱增。2016年11月2日换届，秘书单位设立在溪洛渡水力发电厂（世界第四大水电站）。专委会继往开来，紧抓行业热点和难点，
			积极发挥桥梁和纽带作用，以技术交流研讨会、学术年会、技术培训班为载体，主动为产学研用各方搭建起广泛的学术交流平台，并依托三峡集团、
			长江电力等水电龙头企业以及各委员单位的技术力量，组建了一支理论知识扎实、实践经验丰富的专家服务团队，先后开展了水电站状态检修、反事故安措研讨、
			继电保护及励磁系统技术应用、继电保护精益化管理评价、数字化转型等技术领域的学术交流、技术培训以及各类标准修订发布，为推动水电自动化技术的研究和应用，
			促进行业创新发展起到了积极的作用。
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>天津市机械工程学会</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">天津市机械工程学会成立于1951年，业务主管单位是天津市科学技术协会，是中国机械工程学会地方分会。
			由机械工程相关专业大专院校、科研院所、企事业单位以及从事机械工程相关专业的专家、学者、技术人员组成的学术性非营利性的社团组织。
			天津市机械工程学会业务范围包括学术交流、技术咨询服务、教育培训、编辑出版。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			天津市机械工程学会累计有全国会员5000余人，目前共有分会十四个，天津市机械工程学会及其分会在机械工程各个学术领域引领行业产业、创新发展。
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>天津市电力工程协会简介</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">天津市电力工程协会成立于2013年3月21日，登记机关为天津市社会团体管理局，
			本会在天津市社会团体管理局的业务指导和监督管理下开展工作。协会以服务电力工程企业为宗旨，搭建电力工程企业与社会市场需求、
			能源监管部门之间的桥梁和纽带。为会员提供政策法规、监管动态、行业新闻、协会咨询以及特种作业操作证培训等多方面服务，促进企业交流，
			展现企业形象和风采。协会始终坚持以服务为中心，以需求为导向，以满足为标准，认真履行各项职责，全心全意为会员服务，为电力行业服务，
			为电力行政部门排忧解难 
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>天津仁爱学院绿色水电研究院简介</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">天津仁爱学院绿色水电研究院以绿色能源、清洁能源装备为主要研究领域，
			以绿色能源装备领域的技术创新理论、绿色能源产业创新与创业实践等为研究内容。聘请天津大学国家储能平台王成山院士为学术委员会主席、张俊红教授为专家委员会主席、
			孙江教授为首席研究员、任成祖为院长、于洋洋为常务副院长、贺莹为院长。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			积极跟踪有关领域的国际国内研究动态，开展各级各类项目研究，定期组织相关领域的学术交流活动，多渠道发布研究成果，不断提升学校影响力。
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>支持媒体</strong>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>《机械设计》杂志社</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">
			《机械设计》于1983年创刊，1984年正式订刊名为《机械设计》，由中国科学技术协会批准和主管，中国机械工程学会、天津市机械工程学会等单位主办，
			是我国机械行业类国家级综合性学术期刊，中国机械工程学会机械设计分会会刊、中文核心期刊、中国科学引文数据库（CSCD）收录期刊、
			学位与研究生教育指定中文中重要期刊、中国科技论文统计源期刊等。《机械设计》杂志影响力指数及影响因子等指标逐年攀升，在同类科技期刊中名列前茅，
			多次荣获天津市一级期刊、天津市优秀期刊奖。2020年入选机械工程领域高质量科技期刊分级目录。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			《机械设计》第五届编委会共有编委94人，包括主任委员1人，副主任委员5人，编委中拥有院士11人。
			《机械设计》主编为谭建荣院士，执行主编、社长为王莹正高级工程师，副社长为陈文华教授、冯春生教授。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			2019年《机械设计》开办“创新设计”栏目，先后向路甬祥、潘云鹤、徐志磊、谭建荣等创新设计的领军院士专家约稿，引起了设计学术界的广泛关注。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			2022年《机械设计》刊登论文“面向智能制造全价值链的精益数字孪生体”、“数据驱动的产品创新设计研究”、“群智设计新思维”三篇论文获得“中国机械工程学会优秀论文”。
			《机械设计》编委肖人彬教授论文“数据驱动的产品创新设计研究”入选学术精要高PCSI论文。
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			《机械设计》影响因子在同类科技期刊中名列前茅，学术影响力、影响因子，被引频次不断提升，入选机械工程领域高质量科技期刊分级目录。围绕学科建设，连年开展学术活动、
			决策咨询课题、 科普活动、为广大会员提供知识更新与培训服务，形成了多项品牌活动。
		</span>
	</p>
  </div>
 	
			
  <div class="post-tags-media">
  
  <div class="post-social-media">

  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </section>
  
  
  
   
  
  <a href="javascript:void(0)" class="scroll-top btn-hover" @click="scrollToAnchor('top')">
  <i class="lni lni-chevron-up"></i>
  </a>
	</div>
  </template>
	
  <script>
   
	
	import indexnav from "@/components/nav/indexnav.vue"
	import banner from "@/components/header/banner.vue"
  
  
  export default {
	 name:"IndexPage",
	 components:{
	  indexnav,
	  banner
	 },
	 data(){
	  return{
		  bannerUrl:["BANNER.png"]
	  }
	 },
	 mounted(){
		//  TouchSlide({ 
	  // 		slideCell:"#news_slide",
	  // 		mainCell:".bd ul",
	  // 		effect:"leftLoop",
	  // 		autoPlay:true//自动播放
	  //  });
	  const slide = document.querySelector(".hero-slider");
	  console.log(slide)
		 tns({
			  container: '.hero-slider',
			  items: 1,
			  slideBy: 'page',
			  autoplay: false,
			  mouseDrag: true,
			  gutter: 0,
			  nav: true,
			  controls: false,
			  controlsText: ['<i class="lni lni-arrow-left"></i>', '<i class="lni lni-arrow-right"></i>'],
		  });
	  
  
	   document.addEventListener('click', evnt => {
		  let anchor = evnt.target.closest('a[href^="#"]')
		  if (anchor) {
			  evnt.preventDefault()
			  let value = anchor.getAttribute('set_data');
			  if(value){
				 this.newsDetail(value)
			  }
			 
		  }
		  })
   },
   methods: {
	  downLoadFile(fileName){
		  const fileUrl =  '/img/'+fileName;
		  const link = document.createElement('a');
		  link.href = fileUrl;
		  link.setAttribute('download',fileName);
		  link.click();
	  },
		 goToIndex(){
		 this.$router.push({
			path:"/index"
		  })
	  },
	  scrollToAnchor(id){
		  var anchor = document.getElementById(id);
		  anchor.scrollIntoView();
	  },
	   newsDetail(index){
		  console.log(11111)
		  this.$router.push({
			path:"/newsDetail/"+index
		  })
	   },
	   caseDetail(type){
	   this.$router.push({
			path:"/caseDetail/"+type
		})
	  }
   }
	  
  }
  </script>
	
  <style>
  
	  
	  
  
  </style>