<template>
    <header class="header navbar-area">

<!-- <div class="toolbar-area">
<div class="container">
<div class="row">
<div class="col-lg-8 col-md-6 col-12">
<div class="toolbar-social">
    <div class="menu">
    	<a href="#" class="menu-item" @click="changeLang('en')" style="color: #fff;">English</a><span style="color:#8fc31f">/</span>
    	<a  href="#" class="menu-item" @click="changeLang('zh')" style="color: #fff;">中文</a>
    </div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-12">
<div class="toolbar-login">
<div class="button">
<a href="registration.html">Create an Account</a>
<a href="login.html" class="btn">Log In</a>
</div>
</div>
</div>
</div>
</div>
</div> -->

<div class="container">
<div class="row align-items-center">
<div class="col-lg-12">
<div class="nav-inner">
<nav class="navbar navbar-expand-lg">
<a class="navbar-brand" href=""  @click="toindex()">
<img :src="this.$i18n.locale == 'zh' ? zhLogo : enLogo" alt="Logo">
</a>
<button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
<span class="toggler-icon"></span>
<span class="toggler-icon"></span>
<span class="toggler-icon"></span>
</button>
<div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
<ul id="nav" class="navbar-nav ms-auto">
<li class="nav-item" v-for="(item,index) in $t('classify.title')" :key= "index">
    <a :class="['page-scroll',  'dd-menu', 'collapsed', (index == indexActive || (item.href != '' && path.indexOf(item.href) != -1))? 'active': '']" @click="goToPage(item.href,index,0)" data-bs-toggle="collapse" :data-bs-target="'#submenu-1-'+(index+1) " aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">{{item.title }}</a>
    <ul  v-if="item.child.length > 0" class="sub-menu collapse" :id="'submenu-1-'+(index+1)">
        <li  v-for="(item1,index1) in item.child" :key= "index1" :class="['nav-item',  (index1 == indexActive1 || (item1.href != '' && path == item1.href))? 'active': '']" >
            <a @click="goToPage(item1.href,index,index1)">{{item1.title }}</a>
        </li>
    </ul>
</li>
</ul>
<form class="d-flex search-form">
<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
<button class="btn btn-outline-success" type="submit"><i class="lni lni-search-alt"></i></button>
</form>
</div> 
</nav> 
</div>
</div>
</div> 
</div> 
</header>
   <!-- <index-nav></index-nav> -->
</template>

<script>
 import IndexNav from "@/components/nav/indexnav.vue"



export default {
  name:"HeaderTitle",
  components: {
    IndexNav
  },

 data(){
  return{
    zhLogo: require('../../assets/images/logo1-02.svg'),
    enLogo: require('../../assets/images/logo1-01.svg'),
    titleList: [],
    indexActive: -1,
    indexActive1: -1,
    path:"",
  }
 },
 created(){
   let titles =this.$t('classify.title');
   this.titleList = titles;
   console.log(titles)
 },
 mounted() {
        // new Mmenu(document.querySelector('#menu'))
        let path  = this.$route.path; 
        console.log(path)
        if(path){
            this.path = path;
            this.indexActive = -1;
            this.indexActive1 = -1;
        }
        this.path = path;
        document.addEventListener('click', evnt => {
            let anchor = evnt.target.closest('a[href^="#"]')
            if (anchor) {
                evnt.preventDefault()
            }
        })
    },
watch:{
	$route:{
      handler(val,oldval){
        if(val.path != oldval.path){
            this.path = val.path
            this.indexActive = -1;
            this.indexActive1 = -1;
        }
      },
      // 深度观察监听
      deep: true
    }
},
 methods:{
    changeLang(lang){
        this.$i18n.locale = lang
        localStorage.setItem('lang', lang)// 重要！下面遇到问题里解释
       
    },

    goToPage(path,index,index1){
        if(path != ''){
            this.indexActive = index;
            this.indexActive1 = index1;
            this.$router.push({
              path: path
           })
        }
        
    },
  toindex(){
      this.$router.push({
          path:"/index"
      })
  
  },
  toproduct(type){
      if(!type){
        type =1;
      }
      console.log(type)
      this.$router.push({
          path:"/product/"+type
      })
      
  },
  tonew(){
      this.$router.push({
          path:"/new"
      })
      
  },
  toabout(){
      this.$router.push({
          path:"/about"
      })
      
  },
  tocontentus(){
        this.$router.push({
            path: "/contentus"
        })
  },
  todata(){
    this.$router.push({
            path: "/data"
        })
  },
  tocase(){
    this.$router.push({
            path: "/case/1"
        })
  },
  tonew(type){
    this.$router.push({
            path:"/new/"+type
        })
  },
  
  

 },
  
}
</script>

<style scoped>

  
  

</style>
